import AuthorizationForm from 'components/AuthorizationForm';
import { LOGIN_STEPS } from 'constants';
import { IS_PRODUCTION } from 'constants/environment';
import { useAuth } from 'context/auth.context';
import gtag from 'ga-gtag';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ym from 'react-yandex-metrika';
const LoginCheckForm = ({ showNavigation }) => {
  const { loginCheck, setStep, setUsername } = useAuth();
  const [error, setError] = useState(null);

  const handleSubmit = async (values) => {
    return new Promise((resolve, reject) => {
      loginCheck(values)
        .then((data) => {
          if (data) {
            if (typeof data === 'object' && 'success' in data && !data.success) {
              setError(data?.message || 'Что пошло не так. Попробуйте позже ещё раз.');
            } else {
              setError(null);
              setUsername(values.username);
              setStep(LOGIN_STEPS.SUBMIT);
              try {
                if (IS_PRODUCTION) {
                  gtag('event', 'user_login', {
                    login: 'success',
                  });

                  ym('reachGoal', 'login_success');
                }
              } catch (err) {
                console.error(`Error in LoginForm -> ${err}`);
              }
            }

            resolve(data);
          } else {
            throw new Error('No data from API');
          }
        })
        .catch((e) => {
          setError(e?.response?.data?.message ?? 'Что-то пошло не так');

          reject(error);
        });
    });
  };

  return (
    <>
      <AuthorizationForm
        fields={[
          {
            name: 'username',
            label: 'Телефон или почта',
            type: 'username',
          },
        ]}
        initialValues={{
          username: '',
          // password: '',
        }}
        onSubmit={handleSubmit}
        submitLabel="Продолжить"
        superError={error}
        showNavigation={showNavigation}
        useCaptcha
      />
      <NavLink to="/recovery-password" className="text-green">
        Забыли пароль?
      </NavLink>
    </>
  );
};

LoginCheckForm.defaultProps = {
  showNavigation: true,
};

LoginCheckForm.propTypes = {
  showNavigation: PropTypes.bool,
};

export default LoginCheckForm;
