import axios from 'axios';
import { getAuthApi } from 'utils/api';

class AuthClient {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL: baseURL,
      xsrfHeaderName: 'X-CSRFTOKEN',
      xsrfCookieName: 'csrftoken',
    });
  }

  async get(endpoint, token) {
    const config = {
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
    };

    return this.client
      .get(endpoint, config)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  async post(endpoint, data, { token } = {}) {
    const config = {
      body: data ? JSON.stringify(data) : null,
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return this.client.post(endpoint, data, config);
  }

  login({ username, password, captcha }) {
    return this.post(`login?login=${username}&password=${password}&captcha=${captcha}`);
  }

  loginCheck({ username, captcha }) {
    return this.post(`login/check?login=${username}&captcha=${captcha}`);
  }

  signup(credentials, token) {
    return this.post('register/finish', credentials, {
      token,
    });
  }

  checkPhoneNumber(data) {
    return this.post('check-phone', data);
  }

  registerPhoneNumber(data) {
    return this.post('register/phone', data);
  }

  logout(token) {
    return this.post('logout', null, { token });
  }

  recoverForEmail(email, token) {
    return this.get(`recover?email=${email}`, { token });
  }

  recoverPassword({ email, password, passwordConfirmation, token }) {
    return this.post(
      `recover?token=${token}&email=${email}&password=${password}&password_confirmation=${passwordConfirmation}`,
    );
  }

  refresh() {
    return this.post('refresh');
  }

  getUser(token) {
    return this.get('me', token);
  }

  sendMailConfirmation(email) {
    return this.post(`/send-mail?login=${email}`);
  }

  verify(code) {
    return this.get(`verify/${code}`);
  }
}

const authClient = new AuthClient(getAuthApi());

export default authClient;
