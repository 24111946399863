import AuthorizationForm from 'components/AuthorizationForm';
import { IS_PRODUCTION } from 'constants/environment';
import { useAuth } from 'context/auth.context';
import gtag from 'ga-gtag';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ym from 'react-yandex-metrika';

const initialValues = {
  // password: '',
  // rePassword: '',
  // name: '',
  // email: '',
  // agree: false,
  phone: '+7',
};

const SignUpStart = () => {
  const { checkPhoneNumber, setPhoneNumber, setStep } = useAuth();
  const { search } = useLocation();
  const [error, setError] = useState(null);
  const isDemo = search.indexOf('?demo-message') >= 0;

  const handleSubmit = (values) => {
    if (IS_PRODUCTION) {
      gtag('event', 'click_button', {
        button: 'registration',
      });
      ym('reachGoal', 'click_registration');
    }
    let resultNumber = values.phone?.replaceAll('-', '');
    resultNumber = resultNumber.replaceAll(')', '');
    resultNumber = resultNumber.replaceAll('(', '');
    return checkPhoneNumber({
      phone_number: resultNumber,
      captcha: values.captcha,
    })
      .then((data) => {
        if (data.error) {
          const firstErrorKey = data.error?.[Object.keys(data.error)?.[0]]?.[0];
          setError(firstErrorKey || data?.message || 'Что пошло не так. Попробуйте позже ещё раз.');
        } else {
          if (IS_PRODUCTION) {
            gtag('event', 'user_registration', {
              registration: 'succes',
            });
            ym('reachGoal', 'registration_succes');
          }
          setPhoneNumber(resultNumber);
          setStep(1);
          // data.success = true;
          setError(null);
        }
        return data;
      })
      .catch((error) => {
        const response = error.response || {};
        const data = response.data || {};
        const message = data.message ?? 'Ошибка';
        setError(message);
        // console.log(response, 'response');
      });
  };

  return (
    <AuthorizationForm
      fields={[
        {
          name: 'phone',
          label: 'Номер телефона',
          type: 'tel',
        },
      ]}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitLabel="Регистрация"
      messageText={
        isDemo ? (
          <div className="-mt-4 mb-8 text-gray-500 text-left md:flex md:-mx-36">
            Для доступа к демоданным, вам необходимо зарегистрироваться
          </div>
        ) : undefined
      }
      superError={error}
      useCaptcha
    />
  );
};

export default SignUpStart;
