import { useAuth } from 'context/auth.context';
import { useEffect } from 'react';
import CheckPhoneCode from './CheckPhoneCode';
import SignUpCompleted from './SignUpCompleted';
import SignUpFinish from './SignUpFinish';
import SignUpStart from './SignUpStart';

const SignUp = () => {
  const { step, setStep } = useAuth();

  useEffect(() => {
    return () => {
      setStep(0);
    };
  }, [setStep]);

  if (step === 1) {
    return <CheckPhoneCode />;
  } else if (step === 2) {
    return <SignUpFinish />;
  } else if (step === 3) {
    return <SignUpCompleted />;
  } else {
    return <SignUpStart />;
  }
};

export default SignUp;
